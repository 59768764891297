<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">QR Code Pix</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card" style="height: 1530px; min-width: 1000px">
        <div class="border-0 card-header">
          <div class="float-left invisible ml-3" v-if="isMobile()">.
          </div>
          <button type="button" class="btn btn-sm btn-primary float-left"
                  @click="print()" v-if="isApple()">
            <span class="btn-label"><i class="fas fa-print"></i></span> IMPRIMIR
          </button>
          <button type="button" class="btn btn-sm btn-info float-left"
                  @click="download()" v-if="isMobile()">
            <span class="btn-label"><i class="fas fa-file-download"></i></span> BAIXAR
          </button>
          <br>
          <div class="row">
            <div class="col text-center mb-4">
              <h5>CÓDIGO COPIA E COLA:</h5>
              <span class="text-muted" style="font-size: 10px">{{codeCopyPaste}}</span>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-md-12 mb-3">
              <button type="button" class="btn btn-info btn-sm" @click="copyCode()">
                <span class="btn-label"><i class="fas fa-copy"></i></span> Copiar Código
              </button>
            </div>
          </div>
        </div>
        <object width='100%' height='100%' type="application/pdf"
                :data="'data:application/pdf;base64,'+encodeURI(pdfBase64)"></object>
      </div>
    </div>
  </div>
</template>
<script>
  import {hideLoading, showLoading} from '@/util/loading.utils'
  import {isApple, isMobile, copyText} from '@/util/core.utils'
  import {failWs, postWs} from "@/ws.service";

  export default {
    data() {
      return {
        uuid: null,
        codeCopyPaste: null,
        pdfBase64: null
      }
    },
    created() {
      this.uuid = this.$route.params.uuid;
      if (this.uuid != null) {
        this.loadPdf();
      }
    },
    methods: {
      isApple,
      isMobile,
      copyCode(){
        let successful = false;
        try {
          successful = copyText(this.codeCopyPaste);
        } finally {
          this.$swal({
            title: 'Copiar Código PIX',
            text: (successful ? 'Código PIX copiado com sucesso!' : 'Falha ao copiar Código PIX!'),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-' + (successful ? 'success' : 'danger') + ' btn-fill'
            },
            icon: (successful ? 'success' : 'error'),
            timer: 5000
          })
        }
      },
      loadPdf(){
        postWs("/pix/charge/pdf",
          false,
          {id: this.uuid}, null,
          this.successPrintPdf,
          failWs)
      },
      successPrintPdf(response) {
        this.pdfBase64 = response.data.base64
        this.codeCopyPaste = response.data.qrCode
      },
      download() {
        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + encodeURI(this.pdfBase64);
        a.download = "document.pdf";
        a.click();
      },
      print() {
        let embedEl = "<div class='show-pdf'>" +
          "<object width='1000px' height='1530px' type='application/pdf' data='data:application/pdf;base64," + encodeURI(this.pdfBase64) + "'></object>" +
          "</div>";

        showLoading()
        document.getElementsByClassName('wrapper-aux')[0].innerHTML = embedEl
        setTimeout(function () {
          window.print()
          setTimeout(function () {
            document.getElementsByClassName('wrapper-aux')[0].innerHTML = "";
            hideLoading()
          }, 1000, this)
        }, 3000, this)
      },
      back() {
        this.$router.push(this.pageToBack)
      }
    }
  }

</script>
<style>
  .no-border-card .card-footer {
    border-top: 0;
  }

  @media print {
    .wrapper,
    .is-full-page {
      display: none !important;
    }

    .wrapper-aux div.show-pdf {
      max-width: 100%;
      max-height: 1000px;
      overflow: hidden;
      page-break-after: always;
    }

    .wrapper-aux div.show-pdf object {
      zoom: 0.86; /* Old IE only */
      -moz-transform: scale(0.86);
      -webkit-transform: scale(0.86);
      transform: scale(0.86);
      transform-origin: top left;
    }
  }

  @media print and (hover: none) and (pointer: coarse) {
    .wrapper-aux div.show-pdf {
      max-height: 1200px;
    }
  }
</style>
